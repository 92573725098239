import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Aos from 'aos'
import './selfMailing.css'
import HomeForm from '../../elements/homeForm/homeForm'
import { UserContext } from '../../App'
import MailingShadow from './elements/mailingShadow'
import { Helmet } from 'react-helmet'


const SelfMailing = () => {
    const user = useContext(UserContext)

    const selfMailingPageHeaderInfoSpans = [
        'Доступ в личный кабинет 24/7',
        'Бесплатное видео обучение',
        'Расскажем где брать аккаунты для работы',
        'Техподдержка всегда на связи',
        'Подробная статистика ваших задач',
    ]
    const beneficeSpans = [
        'Без каких либо ограничений на работу с софтами, делайте все что нужно и сколько нужно!.',
        'Бесплатное обучение, подробные видео уроки по работе с софтами!.',
        'Бесплатно раскроем все секреты по работе с аккаунтами для софтов.',
        'Бесплатно раскроем все секреты по работе с прокси и их связью с аккаунтами для софтов.',
        'Работайте с нашими софтами из любой точки мира, и с любого устройства, главное чтобы был интернет',
        'Без скачивания и установки на ваше устройство, больше не нужно переживать, что у вас не Windows.',
        'Работа через личный кабинет, где хранится все ваша информация по задачам.',
        'Автономная работа софтов, даже при выключенном устройстве ваша задача может быть запущена и работать.',
    ]

    useEffect(() => {
        window.scrollTo(0, 0);
        Aos.init({ duration: 1500 })
    }, [])

    return (
        <div className='selfMailingPage'>
            <Helmet>
                <title>
                    {window.location.hostname == 'msk.baksbo.ru' ?
                        'BAKSBO-SOFT - программа для рассылки сообщений в Телеграмм купить в Москве на сайте' :
                        'BAKSBO-SOFT - программа для рассылки сообщений в Телеграм купить на сайте BAKSBO'
                    }
                </title>
                <meta
                    name="description"
                    content={window.location.hostname == 'msk.baksbo.ru' ?
                        'Сервис для рассылки массовых сообщений в Телеграмм BAKSBO-SOFT купить в Москве на сайте онлайн. Софт для автоматической рассылки, парсинга и инвайтинга аудитории в Telegramm от BAKSBO.' :
                        'Программа для рассылки массовых сообщений по чатам Телеграм BAKSBO-SOFT купить на сайте. Софт для спама, авторассылки, парсинга и инвайтинга аудитории в Telegramm от BAKSBO. '
                    }
                />
                <meta
                    name="keywords"
                    content={window.location.hostname == 'msk.baksbo.ru' ?
                        'программа для рассылки сообщений, телеграмм, telegramm, купить, москва, софт, сервис, цена, массовая рассылка, автоматическая рассылка, спам рассылка, письмо, на номер, чат, личные сообщения, канал, отправка сообщений, авторассылка' :
                        'софт для рассылки, по чатам, телеграм, программа, купить, сайт, софт для телеграмма, письмо, приложение, мессенджер, рассылка объявлений, массовая рассылка, отправка сообщений, сервис, авторассылка, парсинг, инвайтинг'
                    }
                />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <Helmet>



            </Helmet>
            <div className='selfMailingPageHeader'>
                <div className='selfMailingPageHeaderInfo'>
                    <h1 className='hiddetH1Seo'>BAKSBO-SOFT - программа для рассылки массовых сообщений {window.location.hostname == 'msk.baksbo.ru' && " в Москве"}</h1>
                    <h4><i>BAKSBO-SOFT</i> это самостоятельная <br />рассылка{window.location.hostname == 'msk.baksbo.ru' && " в Москве"} без ограничений и <br />скачивания!</h4>
                    <h3>Софты работают на любых устройствах и операционной <br />системах Windows, IOS, Android и любые другие.</h3>
                    {selfMailingPageHeaderInfoSpans.map(span =>
                        <span>{span}</span>
                    )}
                    <img className='selfMailingPageHeaderImageMobile' src="/static/img/soft/baksbo_review.png" alt="" />
                    <MailingShadow className='' top='350px' left='30px' opacity='.25' />
                </div>
                <div className='selfMailingPageHeaderImage'>
                    <img src="static/img/soft/selfMailingPageHeaderImg.png" alt="" />
                    <div className='selfMailingPageHeaderImageSHadow'></div>
                    <MailingShadow className='' right='-200px' top='100px' opacity='.3' />
                </div>
            </div>
            <div className="ourUserExperianceDiv">
                <h5>Отзывы наших пользователей </h5>
                <div className='ourUserExperianceImage'></div>
                <Link to={'/review'}><button>Смотреть все</button></Link>
                <MailingShadow className='' right='-200px' top='100px' opacity='.2' />
                <MailingShadow className='' left='-100px' top='500px' opacity='.35' />
            </div>

            <div className="ourSoftBenefice">
                <div className="ourSoftBeneficeInfo">
                    <p>Преимущество работы с нашими софтами{window.location.hostname == 'msk.baksbo.ru' && " в Москве"} </p>
                    {beneficeSpans.map(span =>
                        <span>{span}</span>
                    )}
                </div>
                <div className="ourSoftBeneficeImage">
                    <img src="/static/img/soft/benefice_img.png" alt="" />
                </div>
            </div>
            <div className='softWoldClub'>
                <div className='softWoldClubText'>
                    <h5>Международный  <br /> закрытый клуб</h5>
                    <p>Вступайте в закрытый клуб единамышлиников, в нём обсуждаются все моменты работы и продвижения{window.location.hostname == 'msk.baksbo.ru' && " в Москве"}, также техническая работа и обновление наших софтов.</p>
                    <a target='_blank' href='https://t.me/kvb_baksbo'>
                        <button>Закрытый клуб <img src="/static/img/soft/rounded_arrow.svg" alt="" /> </button>
                    </a>
                </div>
                <div className="softWoldClubimages">
                    <MailingShadow className='' left='-200px' opacity='.25' />
                    <img src="/static/img/soft/telegramiks.png" alt="" />
                </div>
            </div>
            <div className='softTelegram'>
                <div>
                    <p>Telegram</p>
                    <span>Наши две опытные команды из программистов и рассыльщиков создали идеальный{window.location.hostname == 'msk.baksbo.ru' && " в Москве"} софт который закрывает все потребности для привлечении клиентов и поднятии активности в telegram. </span>
                    <span>Данный софт очень прост в использовании, чтобы им пользоваться не нужно иметь профессиональные знания в сфере IT, с ним справиться любой пользователь который умеет пользоваться пк на базовом уровне, </span>
                    <span>также софт максимально продуктивен с точки зрения работы и может выполнять любые задачи которые позволяет telegram на любых устройствах, пк, смартфон, планшет, без привязи!</span>
                    <img src="/static/img/soft/tg_devices.png" alt="" />
                </div>
                <img className='telegrameLogo1' src="/static/img/soft/telegrame-logo1.png" alt="" />
            </div>
            <div className='softTraining'>
                <div className='softTrainingDiv'>
                    <p>Бесплатное обучение </p>
                    <span>Для каждого пользователя будет доступно обучение в личном кабинете, после активации лицензии софта. В разделе обучении вы изучите следующие видео уроки которые помогут вам с легкостью разобраться с софтом{window.location.hostname == 'msk.baksbo.ru' && " в Москве"}:</span>
                    <section className='softTrainingLi'>
                        <img className='softTrainingLiImg' src="/static/img/soft/rounded_arrow.svg" alt="" />
                        <h6>Запуск рассылки сообщений</h6>
                        <h6>Запуск инвайтинга</h6>
                        <h6>Запуск парсинга</h6>
                        <h6>Работа с аккаунтами для задач</h6>
                        <h6>Работа с прокси для аккаунтов</h6>
                    </section>

                    <img src="/static/img/soft/training.png" alt="" />
                </div>
                <MailingShadow className='' top='150px' left='-100px' opacity='.25' />
                <MailingShadow className='' top='-200px' right='-50px' opacity='.25' />
            </div>

            <div className='soft3in1Div'>
                <img src="/static/img/soft/3in1.png" alt="" />
                <img src="/static/img/soft/3in1_mid.png" alt="" />
                <img src="/static/img/soft/3in1_mobile.png" alt="" />
            </div>

            <div className='softPrefer'>
                <h5>
                    Почему нужно выбрать именно наш <span>Telegram софт?</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="173" height="159" viewBox="0 0 173 159" fill="none">
                        <g filter="url(#filter0_d_12_539)">
                            <path d="M22.5253 37.2072C29.1856 56.9068 41.3892 77.702 61.6565 85.7685C74.6086 90.9235 91.6758 87.1248 100.605 76.3655C109.038 66.2042 113.302 52.3722 105.461 40.7731C101.375 34.7272 95.153 31.2448 87.969 34.2751C76.9936 38.9048 73.0918 55.3192 73.8209 65.9123C76.0961 98.9713 99.0344 133.089 129.546 146.4" stroke="#F7D81A" stroke-width="5.69" stroke-linecap="round" />
                            <path d="M111.552 145.648C115.853 146.286 125.653 147.469 130.448 147.09" stroke="#F7D81A" stroke-width="5.69" stroke-linecap="round" />
                            <path d="M130.449 147.091C127.927 143.531 122.417 135.353 120.55 131.113" stroke="#F7D81A" stroke-width="5.69" stroke-linecap="round" />
                        </g>
                        <defs>
                            <filter id="filter0_d_12_539" x="15.6796" y="30.3168" width="121.614" height="127.69" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="2" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12_539" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_12_539" result="shape" />
                            </filter>
                        </defs>
                    </svg>
                </h5>

                <div className='softPreferInfoDiv'>
                    <img className='tg_3d_icon' src="/static/img/soft/tg_3d_icon.png" alt="" />
                    <div className='softPreferInfo'>
                        <div>
                            <span>Высокая скорость работы </span>
                            <span>Уникальная разработка </span>
                            <span>Запуск с любого устройства </span>
                            <span>Без скачивания и установки </span>
                            <span>Техническая поддержка 24/7</span>
                            <span> Без лимитов и ограничений на работу </span>
                            <span>Отправляйте текст, видео, картинки, посты, аудио</span>
                        </div>
                        <div>
                            <span>Подробная статистика </span>
                            <span>Бесплатное обучение </span>
                            <span>Сбор базы из чатов - групп</span>
                            <span>Минимум контроля за задачами</span>
                            <span> Работа сразу с нескольких аккаунтов</span>
                            <span>Добавляйте аккаунты в чат (инвайтинг)</span>
                            <span>Фильтр для отсеивания не активной аудитории</span>
                        </div>
                    </div>
                    <img className='softsSamalyotiks' src="/static/img/soft/samalyotiks.png" alt="" />
                </div>
                <MailingShadow className='' top='100px' red={0} yellow={0} right='-180px' opacity='.35' />
                <MailingShadow className='' top='300px' red={0} yellow={0} left='100px' opacity='.35' />
            </div>
            <div className="softPrices">
                <img className='softBigText' src="/static/img/soft/big_text.png" alt="" />
                <img className='softParashut' src="/static/img/soft/parashut.png" alt="" />
                <div className="softPricesInfo">
                    <Link to={'/cabinet'}>
                        <button>
                            Купить на месяц
                            <img src="/static/img/soft/arrow1.png" alt="" />
                        </button>
                    </Link>
                    <Link to={'/cabinet'}>
                        <button>
                            Купить на 5 часов | 490 ₽
                            <img src="/static/img/soft/arrow2.png" alt="" />
                        </button>
                    </Link>
                    <Link to={'/cabinet'}>
                        <button>
                            Посмотреть софт
                            <img src="/static/img/soft/arrow3.png" alt="" />
                        </button>
                    </Link>

                </div>

                <div className="softPricesLine">
                    <img src="/static/img/soft/line.png" alt="" />
                </div>
                <MailingShadow className='' top='500px' red={0} blue={0} left='-50px' opacity='.35' />
            </div>
            <div className="softWhatsapp">
                <div className="softWhatsappInfo">
                    <h5>WhatsApp</h5>
                    <p>Наши команды в данный момент занимаются разработкой нового софта, в ближайшее время он появится в нашем разделе <i>BAKSBO-SOFT</i></p>
                </div>

                <MailingShadow className='' top='500px' red={0} left='-150px' opacity='.2' />
            </div>
            <div className="selfMailingPageBody">
                <HomeForm />
            </div>
            {window.location.hostname == 'msk.baksbo.ru' ?

                <div className="homeFAQ">
                    <h2 className='faqHeader'>BAKSBO-SOFT</h2>
                    <p>
                        Сервис BAKSBO в Москве предлагает эффективное решение для массовой и автоматической рассылки сообщений в Телеграмм с помощью  BAKSBO-SOFT.  Приобретение данной программы для рассылки сообщений позволяет организациям эффективно распространять информацию, оповещать клиентов о новостях и акциях, а также управлять каналами и чатами в Telegram.
                    </p>
                    <ul>
                        <li>Почему стоит использовать BAKSBO-SOFT для рассылки сообщений в Телеграмм</li>
                        <br />
                        <p>&nbsp;&nbsp;• Автоматическая рассылка: программа позволяет настроить авторассылку писем на номера (логины, юзернеймы), что значительно экономит время.</p>
                        <p>&nbsp;&nbsp;• Доставка сообщений: софт поддерживает отправку личных сообщений или массовую рассылку, обеспечивая точечное общение с нужной аудиторией.</p>
                        <p>&nbsp;&nbsp;• Безопасные рассылки: специальные алгоритмы минимизируют риски попадания в спам, повышая эффективность рассылки.</p>
                        <p>&nbsp;&nbsp;• Соотношение цена-качество: сервис предлагает конкурентные цены на рынке Москвы, делая рассылку доступной для различных видов бизнеса.</p>
                    </ul>
                    <p>
                        Чтобы купить программу для рассылки сообщений в Телеграм, выберите необходимый тариф на нашем сайте или позвоните нам по номеру телефона, расположенному на сайте. Стоимость нашего продукта формируется с учетом потребностей бизнеса и предлагает оптимальное соотношение цены и возможностей программы
                        <br />
                        Сервис BAKSBO-SOFT в Москве предоставляет все необходимые инструменты для эффективной коммуникации в современном мире. Оптимизируйте вашу работу с помощью качественного и надежного инструмента для рассылки в Telegram уже сегодня!
                    </p>
                </div>
                :
                <div className="homeFAQ">
                    <h2 className='faqHeader'>BAKSBO-SOFT</h2>
                    <p>
                        Сервис BAKSBO предлагает решение для автоматической рассылки сообщений в Телеграм с помощью  BAKSBO-SOFT.  С помощью программы компании могут эффективно распространять информацию, уведомлять клиентов о новостях и акциях, а также управлять каналами и чатами в Telegram. Этот софт предоставляет все необходимые инструменты для автоматизации и оптимизации процесса рассылки писем в Телеграм.
                    </p>
                    <ul>
                        <li>Почему стоит использовать BAKSBO-SOFT для рассылки сообщений в Телеграм</li>
                        <br />
                        <p>&nbsp;&nbsp;• Авторассылка: BAKSBO-SOFT позволяет настроить автоматическую рассылку сообщений на номера (логины, юзернеймы), что значительно снижает затраты времени и усилий на этот процесс.</p>
                        <p>&nbsp;&nbsp;• Эффективная доставка: программа поддерживает как индивидуальную отправку сообщений, так и массовые рассылки, что обеспечивает эффективное взаимодействие с целевой аудиторией.</p>
                        <p>&nbsp;&nbsp;• Безопасность сообщений: использование специальных алгоритмов минимизирует риски попадания сообщений в спам, что повышает их доставляемость и прочтение.</p>
                        <p>&nbsp;&nbsp;• Конкурентные цены: сервис BAKSBO предлагает выгодные тарифы на свои услуги, обеспечивая доступность рассылок для компаний различного масштаба.</p>
                        <p>&nbsp;&nbsp;• Надежная поддержка и обновления: пользователи BAKSBO-SOFT получают регулярные обновления программы и круглосуточную техническую поддержку, что гарантирует стабильную и бесперебойную работу.</p>
                        <p>&nbsp;&nbsp;• Парсинг и инвайтинг: программа имеет дополнительные функции для парсинга и инвайтинга аудитории в Телеграм.</p>
                    </ul>
                    <ul>
                        <li>Как купить программу BAKSBO-SOFT для рассылки сообщений в Телеграм</li>
                        <p>Чтобы купить софт для отправки сообщений в Телеграмм, нужно зарегистрироваться на сайте и оформить покупку. Мы стремимся предоставить нашим клиентам лучшие условия для успешной работы и развития. Сервис BAKSBO предоставляет все необходимые инструменты для эффективной коммуникации в современном мире. Оптимизируйте вашу работу с помощью качественного и надежного инструмента BAKSBO-SOFT для работы в Telegram уже сегодня! Наше решение позволит вам максимально эффективно взаимодействовать с вашими клиентами, поддерживать высокий уровень информированности и лояльности аудитории.</p>
                    </ul>
                </div>
            }
        </div>
    )
}

export default SelfMailing

