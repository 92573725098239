import React, { useEffect } from 'react'
// import BotPrice from '../../elements/botPrice/botPrice'
import BotHeader from '../../elements/botHeader/botHeader'
import BotBenefice from '../../elements/botBenefice/botBenefice'
import ManVSbot from '../../elements/manVSbot/manVSbot'
import BotSoundTest from '../../elements/botSoundTest/botSoundTest'
import BotMarketing from '../../elements/botMarketing/botMarketing'
import BotTarifs from '../../elements/botTarifs/botTarifs'
import BotUnderKey from '../../elements/botUnderKey/botUnderKey'
import BotKace from '../../elements/botKace/botKace'
import HomeForm from '../../elements/homeForm/homeForm'
import PayMetods from '../../elements/payMetods/payMetods'
import BotEducation from '../../elements/botEducation/botEducation'
import Support from '../../elements/support/support'
import './bot.css'



const Bot = () => {
  useEffect(() => {
    let link = window.location.href.split('/')
    link = link[link.length - 1]
    if (link === 'bot') {
      window.scrollTo(0, 0)
    }

  }, [])
  return (
    <>
      <BotHeader />
      <BotBenefice />
      <ManVSbot />
      <BotSoundTest />
      <BotMarketing />
      <PayMetods />
      <BotUnderKey />
      <BotKace />
      <BotTarifs />
      <BotEducation />
      <HomeForm platform='bot' />
      {/* <BotPrice /> */}
      <>
        {window.location.hostname == 'msk.baksbo.ru' ?

          <div className="homeFAQ">
            <h2 className='faqHeader'>BAKSBO-BOT</h2>
            <p>
              BAKSBO-BOT в Москве предлагает услуги создания голосового бота для звонков на заказ. Мы имеем большой опыт по разработке и внедрении автоматизированных решений для обзвона, призванных улучшить коммуникацию вашего бизнеса с клиентами. Вы можете легко заказать голосового бота, который будет полностью соответствовать требованиям и задачам вашего бизнеса.
            </p>
            <ul>
              <li>Почему стоит заказать голосового бота от сервиса BAKSBO</li>
              <br />
              <p>&nbsp;&nbsp;• Сокращение расходов на персонал: большинство спам звонков на телефон может обрабатываться автоматически ботом обзвонщиком.</p>
              <p>&nbsp;&nbsp;• Повышение качества обслуживания клиентов: голосовой бот обеспечивает быструю и стандартизированную обратную связь клиентам.</p>
              <p>&nbsp;&nbsp;• Управление воронкой продаж: боты-спамеры и звонилки могут автоматически обрабатывать начальные стадии продаж.</p>
              <p>&nbsp;&nbsp;• Интеграция с вашими бизнес-процессами в Москве: мы предлагаем разработку бота прозвонщика под ключ, что означает полную настройку голосового бота под специфику и потребности вашего предприятия.</p>
            </ul>
            <ul>
              <li>Как заказать голосового бота для звонков</li>
              <p>Чтобы заказать разработку голосового бота для звонков в Москве, заполните форму обратной связи на нашем сайте, либо позвоните по номеру телефона, указанному на сайте. Цена на разработку бота для вашего бизнеса может отличаться в зависимости от требований заказчика. Опытные менеджеры проконсультируют вас по всем вопросам о стоимости и сроках. Воспользуйтесь нашим опытом и технологиями, чтобы максимизировать свою эффективность и увеличить прибыль прямо сейчас!</p>
            </ul>
          </div>
          :
          <div className="homeFAQ">
            <h2 className='faqHeader'>BAKSBO-BOT</h2>
            <p>
              Компания BAKSBO предлагает услугу разработки и внедрения голосового бота для звонков под ключ. Наш бот использует технологии искусственного интеллекта и нейросетей, чтобы обеспечить высокое качество автоматических звонков для вашего бизнеса. BAKSBO-BOT – это идеальное решение для автоматизации процессов прозвона и увеличения эффективности взаимодействия с клиентами.
            </p>
            <ul>
              <li>Преимущества голосового бота для звонков BAKSBO-BOT</li>
              <br />
              <p>&nbsp;&nbsp;• Улучшение качества клиентского сервиса: голосовой бот обеспечивает мгновенную и стандартизированную обратную связь, что повышает общее качество обслуживания клиентов.</p>
              <p>&nbsp;&nbsp;• Автоматизация управления воронкой продаж: боты для обзвона могут эффективно обрабатывать начальные стадии продаж, освобождая ваше время для более важных задач.</p>
              <p>&nbsp;&nbsp;• Полная интеграция с бизнес-процессами: мы предлагаем разработку голосового бота под ключ, включая полную настройку под потребности и специфику вашего бизнеса.</p>
              <p>&nbsp;&nbsp;• Гибкость и многофункциональность: голосовой бот может быть настроен для выполнения различных задач, таких как регистрация на мероприятия, прозвон клиентов, продажи и другие.</p>
              <p>&nbsp;&nbsp;• Экономическая эффективность: наши услуги по разработке и внедрению голосового бота предлагаются по конкурентоспособным ценам, что делает их доступными для бизнеса любого размера.</p>
            </ul>
            <p>
              Как заказать голосового бота для звонков BAKSBO-BOT
              <br />
              <br />
              Чтобы заказать разработку голосового бота для звонков под ключ от компании BAKSBO, заполните форму для обратного звонка на сайте или свяжитесь с нашим менеджером по номеру телефона, расположенному на сайте. Мы предлагаем индивидуальный подход к каждому клиенту, учитывая все его потребности и требования. Наша команда профессионалов обеспечит качественную разработку и внедрение бота, а также предоставит необходимую поддержку и обслуживание.
              <br />
              <br />
              Компания BAKSBO – ваш надежный партнер в автоматизации звонков. Закажите голосового бота для звонков у нас и оцените все преимущества современных технологий для вашего бизнеса. Воспользуйтесь нашим опытом, чтобы повысить свою эффективность и увеличить прибыль уже сегодня!

            </p>
          </div>
        }
      </>
    </>
  )
}

export default Bot