import { useEffect } from 'react';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css'

export default function SelfMailing() {
    const navigate = useNavigate()


    const toSelfmailing = () =>{
        navigate('/bot')
    }

    return (
        <>
            <h5 className='selfMailingHeader'>Самостоятельная рассылка {window.location.hostname == 'msk.baksbo.ru' && "в Москве"}</h5>

            <div className='selfMailingDiv'>

                <div className='selfMailing selfWhatsApp'>
                    <div className='selfMailingText'>
                        <div>
                            <h2>Рассылайте сообщения без ограничений</h2>
                            <p>Теперь вы можете самостоятельно делать рассылки в мессенджеры и соцсети с помощью личного кабинета на нашем сайте. Запусти рассылки за 15 минут.</p>
                            <button>Скоро ...</button>
                        </div>
                    </div>
                    <div className="selfMailingIcon">
                        <img className='self3d' src="static/img/whats3d.png" alt="" />
                        <img className='self2d' src="static/img/icons/gold_whatsApp.png" alt="" />
                    </div>
                </div>

                <div onClick={toSelfmailing} className='selfMailing selfTelegram'>
                    <div className='selfMailingText'>
                        <div>
                            <h2>Рассылайте сообщения без ограничений</h2>
                            <p>Теперь вы можете самостоятельно делать рассылки в мессенджеры и соцсети с помощью личного кабинета на нашем сайте. Запусти рассылки за 15 минут.</p>
                            <button>Перейти</button>
                        </div>
                    </div>
                    <div className="selfMailingIcon">
                        <img className='self3d' src="static/img/tg3d.png" alt="" />
                        <img className='self2d' src="static/img/icons/gold_tg.png" alt="" />
                    </div>
                </div>

            </div>
        </>
    )
}