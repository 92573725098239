import React, { useEffect, useState } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/skyblue';
import '@splidejs/react-splide/css/core';
import '@splidejs/react-splide/css/sea-green';
import './botKace.css'

const BotKace = () => {
    const [sliderData, setSliderData] = useState({
        rewind: true,
        perPage: 2,
        focus: 0,
        omitEnd: true,
        type: 'loop',
        padding: '5rem',
        autoplay: true,
    })
    useEffect(() => {
        if (window.innerWidth <= 1490) {
            setSliderData({
                rewind: true,
                perPage: 1,
                focus: 0,
                omitEnd: true,
                type: 'loop',
                padding: '0',
                autoplay: true,                
            })
            console.log(window.innerWidth);
        }
    }, [])

    const list = [
        { 'header': 'Холодные продажи', 'text': 'Продажа автомобильного масла со скидкой -20%.',                           'call': '8 500','price': '4 890 ', 'count': '271 заявки',        'percent': '+ 35% рост продаж' },
        { 'header': 'Консультация', 'text':     'Записать на бесплатную консультацию по наставничеству предпринимателей.', 'call': '2 300', 'price': '5 210', 'count': '94 заявки',         'percent': '100% закрыта запись на месяц' },
        { 'header': 'Приглашение', 'text':      'Знакомство с клиентом и приглашение его в telegram канал.',               'call': '4 000','price': '2 765 ', 'count': '763 подписчиков', 'percent': '+ 180% новых подписчиков' },
        { 'header': 'Мероприятие', 'text':      'Регистрация на оффлайн мероприятие по инвестированию.',                     'call': '370','price': '633', 'count': '45 регистраций',  'percent': '100% заполненный зал' },
        { 'header': 'Онлайн вебинар', 'text':   'Регистрация на онлайн вебинар по холодной базе.',                        'call': '16 800','price': '8 637',   'count': '544 регистраций', 'percent': '+ 19% рост заявок' },

    ]
    return (
        <>
            <h5 className='botHeaderText'>
                Кейсы голосовых ботов <i>под ключ{window.location.hostname == 'msk.baksbo.ru' && " в Москве"}</i>
            </h5>
            <div className="botKaceDiv">
                <img className='botCaseFon' src="/static/img/bot/back1.png" alt="" />
                <Splide
                    options={sliderData} >
                    {list.map(kace => {
                        return (
                            <SplideSlide>
                                <div className='botKace'>
                                    <h2>{kace['header']}</h2>
                                    <p>{kace['text']}</p>
                                    <span>
                                        <img src="/static/img/bot/fi_phone.png" alt="" />
                                        {kace['call']}
                                    </span>
                                    <span>
                                        <img src="/static/img/bot/u_usd-circle.png" alt="" />
                                        {kace['price']} руб.
                                    </span>
                                    <span>
                                        <img src="/static/img/bot/fi_check.png" alt="" />
                                        {kace['count']}
                                    </span>
                                    <span>
                                        <img src="/static/img/bot/fi_slash.png" alt="" />
                                        {kace['percent']}
                                    </span>
                                </div>
                            </SplideSlide>
                        )

                    })}
                </Splide>
            </div>
        </>
    )
}

export default BotKace