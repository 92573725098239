import axios from "axios"
import { useRef, useState, useEffect } from "react"
import Bounus from "../../elements/bonus/bonus"
import ChatFon from "../../elements/chatFon/ChatFon"
import MailingForm from "../../elements/mailingForm/mailingForm"
import Aos from 'aos'
import 'aos/dist/aos.css'
import MetaTags from 'react-meta-tags';
import Case from "../../elements/case/case"
import Benefice from "../../elements/benefice/benefice"
import StatisticCase from "../../elements/statisticKase/statisticCase"
import { Link } from "react-router-dom"
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import BotCommercial from "../../elements/botCommercial/botCommercial"
import SelfMailing from "../../elements/selfMailing/selfMailing"

import { Helmet } from 'react-helmet';



export default function Instagram() {
    const toMailing = useRef('')
    const scroll = useRef('')
    const finger = useRef('')
    const tarifFon = useRef('')
    const intervalRef = useRef('')
    const [scrollpPos, setScrollpPos] = useState(2)
    const [tarifFonScroll, setTarifFonScroll] = useState(null)


    useEffect(() => {
        Aos.init({ duration: 1500 })
        setTarifFonScroll(true)
    }, [])


    useEffect(() => {
        if (tarifFonScroll === true) {
            if (document.body.offsetWidth < 950) {
                intervalRef.current = setInterval(function () {
                    tarifFon.current.scrollTo(0, 0)
                    setTimeout(() => {
                        tarifFon.current.scrollTo(tarifFon.current.scrollWidth, 0)
                    }, 2000);
                }, 5000);
            }
        }
    }, [tarifFonScroll])


    function canselScroll() {
        setTarifFonScroll(false)
        clearInterval(intervalRef.current)
        finger.current.style.display = 'none'
    }


    let selfDivWidth = 950
    if (document.body.offsetWidth < 1100) {
        selfDivWidth = 810
    }
    if (document.body.offsetWidth < 900) {
        selfDivWidth = 710
    }
    if (document.body.offsetWidth < 700) {
        selfDivWidth = 360
    }
    let width = (document.body.offsetWidth - selfDivWidth)

    let positions = []
    let count = 0
    for (let i = 0; i < 5; i++) {
        positions.push(count)
        count += selfDivWidth
    }

    useEffect(() => {
        setScrollpPos(2)
    }, [])

    const [price, setPrice] = useState('')
    const [base, setBase] = useState('')
    const [tarif1, setTarif1] = useState('')
    const [tarif1_sale, setTarif1_sale] = useState('')
    const [tarif1_base, setTarif1_base] = useState('')
    const [tarif1_price, setTarif1_price] = useState('')
    const [tarif1_all, setTarif1_all] = useState('')
    const [tarif2, setTarif2] = useState('')
    const [tarif2_sale, setTarif2_sale] = useState('')
    const [tarif2_base, setTarif2_base] = useState('')
    const [tarif2_price, setTarif2_price] = useState('')
    const [tarif2_all, setTarif2_all] = useState('')
    const [tarif3, setTarif3] = useState('')
    const [tarif3_sale, setTarif3_sale] = useState('')
    const [tarif3_base, setTarif3_base] = useState('')
    const [tarif3_price, setTarif3_price] = useState('')
    const [tarif3_all, setTarif3_all] = useState('')
    const [tarif4, setTarif4] = useState('')
    const [tarif4_sale, setTarif4_sale] = useState('')
    const [tarif4_base, setTarif4_base] = useState('')
    const [tarif4_price, setTarif4_price] = useState('')
    const [tarif4_all, setTarif4_all] = useState('')
    const [tarif5, setTarif5] = useState('')
    const [tarif5_sale, setTarif5_sale] = useState('')
    const [tarif5_base, setTarif5_base] = useState('')
    const [tarif5_price, setTarif5_price] = useState('')
    const [tarif5_all, setTarif5_all] = useState('')
    const [tarif6, setTarif6] = useState('')
    const [tarif6_sale, setTarif6_sale] = useState('')
    const [tarif6_base, setTarif6_base] = useState('')
    const [tarif6_price, setTarif6_price] = useState('')
    const [tarif6_all, setTarif6_all] = useState('')

    useEffect(() => {
        axios.get('/api/instagram')
            .then(request => request.data.social)
            .then(request => {
                setPrice(request.price)
                setBase(request.base)
                setTarif1(request.tarif1)
                setTarif1_sale(request.tarif1_sale)
                setTarif1_base(request.tarif1_base)
                setTarif1_price(request.tarif1_price)
                setTarif1_all(request.tarif1_all)
                setTarif2(request.tarif2)
                setTarif2_sale(request.tarif2_sale)
                setTarif2_base(request.tarif2_base)
                setTarif2_price(request.tarif2_price)
                setTarif2_all(request.tarif2_all)
                setTarif3(request.tarif3)
                setTarif3_sale(request.tarif3_sale)
                setTarif3_base(request.tarif3_base)
                setTarif3_price(request.tarif3_price)
                setTarif3_all(request.tarif3_all)
                setTarif4(request.tarif4)
                setTarif4_sale(request.tarif4_sale)
                setTarif4_base(request.tarif4_base)
                setTarif4_price(request.tarif4_price)
                setTarif4_all(request.tarif4_all)
                setTarif5(request.tarif5)
                setTarif5_sale(request.tarif5_sale)
                setTarif5_base(request.tarif5_base)
                setTarif5_price(request.tarif5_price)
                setTarif5_all(request.tarif5_all)
                setTarif6(request.tarif6)
                setTarif6_sale(request.tarif6_sale)
                setTarif6_base(request.tarif6_base)
                setTarif6_price(request.tarif6_price)
                setTarif6_all(request.tarif6_all)
            }
            )
        window.scroll(0, 0)
    }, [])
    const [review, setReview] = useState([['static/img/social/instagram/screen2.png', 1], ['static/img/social/instagram/screen1.png', 2], ['static/img/social/instagram/screen3.png', 3], ['static/img/social/instagram/screen4.png', 4]])
    return (
        <>
            <Helmet>
                <title>
                    {window.location.hostname == 'msk.baksbo.ru' ?
                        'Массовая рассылка сообщений в Инстаграм (Instagram) заказать в Москве в сервисе BAKSBO' :
                        'Массовая рассылка сообщений в Инстаграм (Instagram) заказать в сервисе BAKSBO'
                    }
                </title>
                <meta
                    name="description"
                    content={window.location.hostname == 'msk.baksbo.ru' ?
                        'Массовая рассылка Инстаграм сообщений в директе заказать в Москве в онлайн-сервисе BAKSBO. Купить Direct рассылку в Instagram аккаунтах цена услуги недорого на сайте нашей компании.' :
                        'Массовая рассылка писем в Директ (Direct) Инстаграм заказать в сервисе BAKSBO. Купить Instagram рассылку сообщений для подписчиков или клиентов, примеры постов и цены на сайте. '
                    }
                />
                <meta
                    name="keywords"
                    content={window.location.hostname == 'msk.baksbo.ru' ?
                        'рассылка в Instagram, инстаграм, заказать, москва, сервис, директ, direct, аккаунт, цена, автоматическая, свой подписчик, безопасная, много, пост, холодный, бан, открытый диалог, после подписки, купить' :
                        'массовая рассылка в Instagram, инстаграм, заказать, сервис, директ, direct, аккаунт, цена, автоматическая, подписчик, купить, сообщения, письма, клиент, инста, пост, много, по диалогам, своим подписчикам '
                    }
                />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <ChatFon />
            <div className='homeHeader'>
                <div className="eclipse instagramEclipse"></div>

                <div className="homeHeaderText">
                    <h1 className='hiddetH1Seo'>Рассылка сообщений в direct Instagram {window.location.hostname == 'msk.baksbo.ru' && " в Москве"}</h1>
                    <h4>Рассылка сообщений{window.location.hostname == 'msk.baksbo.ru' && " в Москве"}</h4>
                    <h2>в direct instagram от {price}<b>₽</b></h2>
                    <p>Продавайте свой продукт целевой аудитории гораздо быстрее и качественней, <i>каждое сообщение доходит до клиента с уведомлением.</i></p>
                    <div className='homeHeaderButtonDiv'>
                        <a href="#toTarif">
                            <button className='getPresent'>Tарифы</button>
                        </a>
                        <Link to="/#freeConsultation">
                            <button className='getPresent'>Консультация</button>
                        </Link>
                        <div className="headerSocialLinks">
                            <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/baksbo.ru/">
                                <img src="static/img/icons/insta.png" alt="" />
                            </a>

                            <a target="_blank" rel="noopener noreferrer" href="https://t.me/baksbo_ru">
                                <img src="static/img/icons/tg.png" alt="" />
                            </a>

                            <a target="_blank" rel="noopener noreferrer" href="https://wa.me/+789209660591">
                                <img src="static/img/icons/waapp.png" alt="" />
                            </a>

                            <a target="_blank" rel="noopener noreferrer" href="https://vk.com/baksbo_ru">
                                <img src="static/img/icons/vk.png" alt="" />

                            </a>
                        </div>
                    </div>
                    <div className='rates'>
                        <div className="rate">
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star2.png" alt="" />
                            <span>4.8 Яндекс</span>
                        </div>
                        <div className="rate">
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star2.png" alt="" />
                            <span>4.9 Google</span>
                        </div>
                        <div className="rate">
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <span>5.0 Авито</span>
                        </div>
                        <div className="rate">
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <span>5.0 Wdomain</span>
                        </div>
                    </div>
                </div>
                <div className="homeHeaderImage">
                    <img src="static/img/social/instagram/hand.png" alt="" />
                </div>
            </div>

            <p className='headerSocialText'>Мы тут есть</p>
            <div id='headerSocialLinks' className="headerSocialLinks">
                <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/baksbo.ru/">
                    <img src="static/img/icons/insta.png" alt="" />
                </a>

                <a target="_blank" rel="noopener noreferrer" href="https://t.me/baksbo_ru">
                    <img src="static/img/icons/tg.png" alt="" />
                </a>

                <a target="_blank" rel="noopener noreferrer" href="https://wa.me/+789209660591">
                    <img src="static/img/icons/waapp.png" alt="" />
                </a>

                <a target="_blank" rel="noopener noreferrer" href="https://vk.com/baksbo_ru">
                    <img src="static/img/icons/vk.png" alt="" />

                </a>
            </div>
            <StatisticCase />
            <div className="socailScreensSlider">
                <h2>4 Варианта сообщений</h2>
                <Swiper
                    rewind={true}
                    slidesPerView={'3'}
                    centeredSlides={true}
                    spaceBetween={30}
                    loop={true}
                    autoplay={true}
                    navigation={true}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination, Autoplay, Navigation]}
                    className="socialSwiper"
                >

                    <SwiperSlide>
                        <div className="screenshot">
                            <p>Текст + ссылки</p>
                            <div className="workLine socialLine"></div>
                            <span>вариант №2</span>
                            <img src="static/img/social/instagram/screen1.png" alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="screenshot">
                            <p>ПОСТ</p>
                            <div className="workLine socialLine"></div>
                            <span>вариант №3</span>
                            <img src="static/img/social/instagram/screen3.png" alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="screenshot">
                            <p>Ссылка на аккаунт + текст + ссылки</p>
                            <div className="workLine socialLine"></div>
                            <span>вариант №4</span>
                            <img src="static/img/social/instagram/screen4.png" alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="screenshot">
                            <p>ПОСТ + ТЕКСТ + ССЫЛКИ </p>
                            <div className="workLine socialLine"></div>
                            <span>вариант №1</span>
                            <img src="static/img/social/instagram/screen2.png" alt="" />
                        </div>
                    </SwiperSlide>
                </Swiper>
                <span className="socailScreensSliderspan">Если у вас возникли вопросы по рассылке информации, то вы можете задать их нашему менеджеру в мессенджер или по телефону.</span>
                <p className="socailScreensSliderParagraph">*отправляется только один вариант</p>
            </div>
            <div className="socailScreens">
                <h2>Варианты сообщений</h2>
                <div className="socailScreensSection">
                    <div className="screenshot">
                        <p>ПОСТ + ТЕКСТ + ССЫЛКИ </p>
                        <div className="workLine socialLine"></div>
                        <span>вариант №1</span>
                        <img src="static/img/social/instagram/screen2.png" alt="" />
                    </div>
                    <div className="screenshot">
                        <p>Текст + ссылки</p>
                        <div className="workLine socialLine"></div>
                        <span>вариант №2</span>
                        <img src="static/img/social/instagram/screen1.png" alt="" />
                    </div>
                    <div className="screenshot">
                        <p>ПОСТ</p>
                        <div className="workLine socialLine"></div>
                        <span>вариант №3</span>
                        <img src="static/img/social/instagram/screen3.png" alt="" />
                    </div>
                    <div className="screenshot">
                        <p>Ссылка на аккаунт + текст + ссылки</p>
                        <div className="workLine socialLine"></div>
                        <span>вариант №4</span>
                        <img src="static/img/social/instagram/screen4.png" alt="" />
                    </div>
                </div>
                <span>Если у вас возникли вопросы по рассылке информации, то вы можете задать их нашему менеджеру в мессенджер или по телефону.</span>
                <p>*отправляется только один вариант</p>
            </div>
            <Case />
            <Benefice />
            <div className="mailingDiv">
                <div className='workSection socialWorkSection'>
                    <div data-aos="fade-up" className="workImage"><img src="static/img/social/instagram/logo.png" alt="" /></div>
                    <div className="workText">
                        <h5>РАСКАЧАЙТЕ СВОЙ INSTAGRAM</h5>
                        <div className='workLine'></div>
                        <p><i>НАЧНИТЕ ЭКОНОМИТЬ СВОИ ДЕНЬГИ НА РЕКЛАМЕ</i></p>
                        <span>Конверсия рассылки сообщений в разы превышает конверсию обычной директ рекламы, тем самым вы сможете привлекать больше потенциальных клиентов за меньший рекламный бюджет, <i>так как стоимость одного сообщения фиксирована и не может быть изменена,</i> а при директ рекламе стоимость целевого клиента в десятки раз выше.</span>
                    </div>
                </div>
                <div id="toTarif" className="tarifDiv">
                    <h2>тарифы</h2>
                    <p>ОПЛАТА ПРОИЗВОДИТСЯ СТРОГО ПОСЛЕ ПОДТВЕРЖДЕНИЯ ЗАКАЗА МЕНЕДЖЕРОМ</p>
                    <div onClick={canselScroll} onTouchStart={canselScroll} onTouchMove={canselScroll} ref={tarifFon} className="tarifFon">
                        <div onTouchStart={canselScroll} onTouchMove={canselScroll} className="tarifs">
                            <div className="tarifInfo">
                                <p>Количество сообщений</p>
                                <span>от {tarif1} шт.</span>
                                <span>от {tarif2} шт.</span>
                                <span>от {tarif3} шт.</span>
                                <span>от {tarif4} шт.</span>
                                <span>от {tarif5} шт.</span>
                                <span>от {tarif6} шт.</span>
                            </div>
                            <div className="tarifLine"></div>
                            <div className="tarifInfo">
                                <p>Стоимость за сообщение</p>
                                <span>{tarif1_price} ₽</span>
                                <span>{tarif2_price} ₽</span>
                                <span>{tarif3_price} ₽</span>
                                <span>{tarif4_price} ₽</span>
                                <span>{tarif5_price} ₽</span>
                                <span>{tarif6_price} ₽</span>
                            </div>
                            <div className="tarifLine"></div>
                            {base !== 'none' ?
                                <>
                                    <div className="tarifInfo">
                                        <p>Стоимость базы <br />(наш сбор {base} ₽)</p>
                                        <span>{tarif1_base} ₽</span>
                                        <span>{tarif2_base} ₽</span>
                                        <span>{tarif3_base} ₽</span>
                                        <span>{tarif4_base} ₽</span>
                                        <span>{tarif5_base} ₽</span>
                                        <span>{tarif6_base} ₽</span>
                                    </div>
                                    <div className="tarifLine"></div>
                                </>
                                :
                                null
                            }

                            <div className="tarifInfo">
                                {base !== 'none' ?
                                    <p>Итоговая стоимость Рассылка + База</p>
                                    :
                                    <p>Стоимость рассылки</p>
                                }
                                <span>{tarif1_all} ₽</span>
                                <span>{tarif2_all} ₽</span>
                                <span>{tarif3_all} ₽</span>
                                <span>{tarif4_all} ₽</span>
                                <span>{tarif5_all} ₽</span>
                                <span>{tarif6_all} ₽</span>
                            </div>
                            <div className="tarifLine"></div>
                            <div className="tarifInfo">
                                <p>Скидка</p>
                                <span>- {tarif1_sale} %</span>
                                <span>- {tarif2_sale} %</span>
                                <span>- {tarif3_sale} %</span>
                                <span>- {tarif4_sale} %</span>
                                <span>- {tarif5_sale} %</span>
                                <span>- {tarif6_sale} %</span>
                            </div>
                        </div>
                        <img ref={finger} className="finger" src="/static/img/finger.png" alt="" />
                        <a href="#mailingFomr">
                            <button>Заказать рассылку</button>
                        </a>
                    </div>
                </div>
                <div className="legs">
                    <Bounus />
                </div>

                <div className='workSection workTextOtchyot'>
                    <div className="workText">
                        <h5>ОТЧЁТНОСТЬ ДО/ПОСЛЕ РАБОТЫ</h5>
                        <div className='workLine'></div>
                        <p>НАМ НЕЧЕГО СКРЫВАТЬ ОТ ВАС</p>
                        <span>
                            Отчетность отправленных сообщений предоставляется в два этапа.
                            <br /><br />
                            <b>Первый</b> - это скрины экрана пк, где мы показываем, что рассылка запущена и производится в штатном режиме.
                            <br /><br />
                            <b>Второй</b> - это скрин экрана пк, где мы показываем, какое количество сообщений было успешно отправлено пользователям в direct.
                        </span>
                    </div>
                    <div data-aos="fade-up" className="workImage"><img src="static/img/lists.png" alt="" /></div>
                </div>
                <div id="toMailing" ref={toMailing}></div>
                <MailingForm platform={'instagram'} />

                <h2 className='faqHeader'>Часто задаваемые вопросы</h2>
                <div className='homeFAQ'>
                    <ul>
                        <li>Могут ли заблокировать мой Instagram аккаунт?</li>
                        <br />
                        <p>- Вам не нужно беспокоиться за свой аккаунт, так как мы используем новейшие методы обхода блокировок и при рассылке сообщений ваш аккаунт находится в полной безопасности, он не в коем случае не используется в работе. Рассылка производится с наши рабочих и отлёжанных аккаунтов, которые предназначены для массовой рассылки сообщений. Рассылка производится с наших аккаунтов из-за того, что один аккаунт не в силе отправить большое количество сообщений за сутки, так как есть ограничения со стороны самого инстаграма и если нарушить эти правила, то инстаграм может заблокировать тот аккаунт с которого идёт рассылка или дать теневой бан, поэтому эту задачу выполняют сотни аккаунтов для снижения риска блокировки и повышения качества самой рассылки. За всё время работы ни один наш заказчик не получил ограничения на свой аккаунт.</p>
                    </ul>
                    <ul>
                        <li>Куда попадают сообщения при рассылке в Instagram?</li>
                        <br />
                        <p>- Все сообщения отправляются каждому аккаунту в личные сообщения директ, но в директе есть три вида принятия сообщений, это принятие зависит по большей степени от самого инстаграма и от качества аккаунтов, которые делают массовую рассылку. Эти три вида получения сообщения, следующие: </p>
                        <p>&nbsp;&nbsp;• основные заявки</p>
                        <p>&nbsp;&nbsp;• скрытые заявки </p>
                        <p>&nbsp;&nbsp;• все заявки </p>
                        <p>В нашем случае мы стараемся отправлять в основные заявки с PUSH уведомлением о получении сообщения в директ, тем самым увеличиваем конверсию самой рассылки. Достичь этого нам позволяют качественные и отлёжанные аккаунты и строгий человеческий контроль самой рассылки в момент её исполнения.</p>
                    </ul>
                    <ul>
                        <li>Что можно отправлять в Instagram?</li>
                        <br />
                        <p>- К отправке доступно четыре вида сообщений, все они указаны визуально и описаны в самом начале данной страницы. Отправлять можно следующее: </p>

                        <p>&nbsp;&nbsp;• Пост + текст + до 3х ссылок</p>
                        <p>&nbsp;&nbsp;• Текст + до 3х ссылок</p>
                        <p>&nbsp;&nbsp;• Пост</p>
                        <p>&nbsp;&nbsp;• Ссылка на аккаунт + текст + до 3х ссылок</p>
                        <p>Зачастую для отправки используют только два основных вида сообщений, это текст + до 3х ссылок и пост + текст + до 3х ссылок, в тексте можно указать ссылки на любые ресурсы. Пост может быть, как видео, так и картинка. В качестве БОНУСА наш модератор поможет вам с написанием текста для рассылки, он структуризирует ваше сообщение и сделает его уникальным, тем самым максимально увеличит конверсию. Каждый текст редактируется индивидуально, без каких-либо шаблонов.</p>
                    </ul>
                    <ul>
                        <li>Поможете собрать базу для Instagram рассылки {window.location.hostname == 'msk.baksbo.ru' && " в Москве"}?</li>
                        <br />
                        <p>- Если у вас нет своей базы для рассылки, то мы поможем вам с этим. Наша команда аналитиков проанализирует вашу нишу и подберёт ресурсы для сбора базы{window.location.hostname == 'msk.baksbo.ru' && " в Москве"}, после этого вам высылают их на согласование, чтобы вы могли лично посмотреть, от куда мы планируем собирать базу. Если у вас уже есть пожелания, от куда вы хотите собрать базу, то высылайте ссылки на ресурсы и мы приступим к сборке. Также при сборке база фильтруется для отсеивания ненужных нам для рассылки пользователей. Виды фильтрации в каждом виде рассылки имеет свои настройки, если вам необходимо узнать, какие настройки в фильтре мы можем сделать в той или иной рассылке, то свяжитесь с нашим менеджером или оставьте заявку на бесплатную консультацию и мы вам обо всем подробно расскажем.</p>
                    </ul>
                    <ul>
                        <li>Что такое рассылка сообщений под ключ?</li>
                        <br />
                        <p>- Сбор и фильтрация базы в инстаграме производится абсолютно бесплатно как дополнительная функция к самой рассылке, вы не оплачиваете ее отдельно. Базу мы можем собрать:</p>
                        <p>&nbsp;&nbsp;• Подписчиков любых открытых аккаунтов</p>
                        <p>&nbsp;&nbsp;• Подписки любых открытых аккаунтов</p>
                        <p>&nbsp;&nbsp;• По хэштегам</p>
                        <p>&nbsp;&nbsp;• По геолокации</p>
                        <p>&nbsp;&nbsp;• Кто ставит лайки под постами</p>
                        <p>&nbsp;&nbsp;• Кто пишет комментарии под постами</p>
                        <p>Если у вас есть пожелания от куда вы хотите собрать базу, то можете выслать ссылки на эти аккаунты или написать хэштеги и мы соберём именно от туда. Если у вас таких пожеланий нет, то наша команда аналитиков сами подберут для вас подходящие аккаунты или хэштеги для сбора и вышлет их вам на согласование, чтобы вы подтвердили сбор и знали от, куда собирается ваша база для рассылки. Также базу мы фильтруем от ботов и бизнес аккаунтов, если есть в этом необходимость. Настройки фильтра всегда согласовывается с вами. После сборки и фильтрации, базу по запросу можем выслать вам в айди, в дальнейшем вы можете работать с ней по другим задачам.</p>

                    </ul>
                    <ul>
                        <li>Сколько стоит рассылка сообщений в Instagram?</li>
                        <br />
                        <p>- Мы - команда профессионалов с большим опытом работы в сфере лидогенирации через рассылки в Instagram, четко понимаем, что необходимо для рассылки именно в той нише, где находится ваш бизнес. Поэтому мы решили сделать рассылку под ключ. Наша команда подготовит полностью все этапы для запуска рассылки в Instagram. Вам остаётся только согласовывать некоторые этапы работы.</p>
                        <p>Этапы подготовки:</p>
                        <p>&nbsp;&nbsp;• Аналитика вашей ниши</p>
                        <p>&nbsp;&nbsp;• Поиск аккаунтов и хэштегов для сбора базы</p>
                        <p>&nbsp;&nbsp;• Сбор целевой базы{window.location.hostname == 'msk.baksbo.ru' && " в Москве"}</p>
                        <p>&nbsp;&nbsp;• Фильтрация целевой базы </p>
                        <p>&nbsp;&nbsp;• Определение структуризации сообщений</p>
                        <p>&nbsp;&nbsp;• Помощь в написании текста для рассылки</p>
                        <p>&nbsp;&nbsp;• Запуск рассылки (с предоставлением отчетов)</p>
                        <p>&nbsp;&nbsp;• Поддержка клиентского отдела на протяжении всей работы</p>

                    </ul>
                </div>
                {window.location.hostname == 'msk.baksbo.ru' ?

                    <div className="homeFAQ">
                        <h2 className='faqHeader'>Рассылка в Instagram</h2>
                        <p>
                            Массовая рассылка сообщений – это эффективный способ продвижения бизнеса и привлечения новой аудитории. Сервис BAKSBO в Москве предоставляет услугу массовой рассылки сообщений в Инстаграм (Instagram), предоставляя удобные инструменты для взаимодействия с вашими подписчиками и потенциальными клиентами. Наш сервис предоставляет возможность автоматической рассылки сообщений через Instagram Direct, что позволяет существенно оптимизировать ваше взаимодействие с клиентами.
                        </p>
                        <ul>
                            <li>Для чего покупают массовую рассылку в Instagram в BAKSBO</li>
                            <br />
                            <p>&nbsp;&nbsp;• Привлечение новых подписчиков: рассылка сообщений помогает увеличить число подписчиков вашего аккаунта, привлекая новую аудиторию.</p>
                            <p>&nbsp;&nbsp;• Безопасность аккаунта: мы обеспечиваем безопасную рассылку, чтобы избежать риска бана аккаунта или холодных подписок.</p>
                            <p>&nbsp;&nbsp;• Оповещение о событиях: массовая рассылка сообщений помогает оперативно информировать об изменениях в работе компании или предстоящих мероприятиях в сети. </p>
                            <p>&nbsp;&nbsp;• Продвижение товаров и услуг: рассылка сообщений в Instagram позволяет эффективно рекламировать ваши товары или услуги в Москве через посты и Директ сообщения и др.. </p>
                        </ul>
                        <ul>
                            <li>Как заказать массовую рассылку сообщений в Instagram от BAKSBO</li>
                            <p>Чтобы заказать массовую рассылку сообщений в Instagram для своих подписчиков в Москве, заполните форму обратной связи на сайте или свяжитесь с нами по номеру телефона, указанному на сайте. Наши менеджеры проконсультируют вас по всем вопросам, помогут определиться с выбором подходящего формата рассылки и оформят ваш заказ. Свяжитесь с нами уже сегодня и начните использовать все преимущества этого мощного инструмента для вашего успеха в социальных сетях.</p>

                        </ul>
                    </div>
                    :
                    <div className="homeFAQ">
                        <h2 className='faqHeader'>Рассылка в Instagram</h2>
                        <p>
                            Массовая рассылка сообщений в Instagram является одним из самых эффективных инструментов для привлечения и удержания клиентов в современном цифровом мире. В сервисе BAKSBO вы можете заказать услугу массовой рассылки сообщений в Instagram по доступной цене. Наш сервис обеспечивает автоматическую рассылку сообщений через direct (директ), что позволяет охватить большое количество подписчиков и потенциальных клиентов.
                        </p>
                        <ul>
                            <li>Преимущества массовой рассылки сообщений в Instagram</li>
                            <br />
                            <p>&nbsp;&nbsp;• Широкий охват аудитории: Instagram – одна из самых популярных социальных сетей, что позволяет вам охватить огромное количество пользователей, включая ваших текущих подписчиков.</p>
                            <p>&nbsp;&nbsp;• Высокая вовлеченность: пользователи Instagram активно взаимодействуют с контентом и постами, что повышает вероятность получения откликов на ваши сообщения.</p>
                            <p>&nbsp;&nbsp;• Персонализированные письма: можно настраивать сообщения, адаптируя их под конкретные группы подписчиков, что делает коммуникацию более целевой и эффективной.</p>
                            <p>&nbsp;&nbsp;• Прямое взаимодействие: сообщения отправляются напрямую в директ (direct), что обеспечивает мгновенное получение и просмотр сообщений вашими подписчиками в диалогах</p>
                        </ul>
                        <ul>
                            <p>
                                Чтобы купить услугу массовой рассылки сообщений в Инстаграм в сервисе BAKSBO, заполните форму для обратного звонка или свяжитесь с нашим менеджером для получения консультации по номеру телефона на сайте. Мы предлагаем гибкие условия оплаты и различные пакеты услуг, что позволяет подобрать оптимальное решение для вашего бизнеса.
                                <br />
                                <br />
                                Использование массовой рассылки в Instagram через BAKSBO позволяет не только повысить узнаваемость бренда, но и установить долгосрочные отношения со своими подписчиками. Это инвестиция в будущее вашего бизнеса.

                            </p>

                        </ul>
                    </div>
                }
            </div>

        </>
    )
}