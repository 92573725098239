import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

const BotHeader = () => {
  return (
    <>
      <Helmet>
        <title>
          {window.location.hostname == 'msk.baksbo.ru' ?
            'BAKSBO-BOT - голосовой бот для звонков заказать в Москве цена на сайте' :
            'BAKSBO-BOT - голосовой бот для звонков заказать под ключ от компании BAKSBO'
          }
        </title>
        <meta
          name="description"
          content={window.location.hostname == 'msk.baksbo.ru' ?
            'Голосовой бот для звонков заказать в Москве цена разработки под ключ в сервисе BAKSBO. Бот для обзвона, продаж или спама звонков на телефон, услуга создания на нашем сайте.' :
            'Голосовой бот для звонков заказать разработку под ключ цена в компании BAKSBO. Купить бот для обзвона клиентов, продаж или спама звонков на телефон, услуга создания в нашем сервисе.'
          }
        />
        <meta
          name="keywords"
          content={window.location.hostname == 'msk.baksbo.ru' ?
            'бот для звонков, голосовой бот, заказать, на заказ, москва, цена, разработка, создание, сервис, обзвонщик, для бизнеса, спам звонки, услуга, звонок на телефон, стоимость, воронка продаж, спамер, прозвонщик, звонилка, под ключ' :
            'голосовой бот для звонков, спам бот, искусственный инетеллект, нейросеть, бот обзвонщик, купить, заказать, цена, разработка, под ключ, продажи, создание, сервис, компания, для обзвона, клиент, регистрация на мероприятие, создание, прозвон'
          }
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className='botHeder homeHeader'>
        <div className="botHederFon">
          <img src="/static/img/bot/back1.5.png" alt="" />
        </div>
        <div className="homeHeaderText">
          <h1 className='hiddetH1Seo'>BAKSBO-BOT - голосовой бот для звонков {window.location.hostname == 'msk.baksbo.ru' && " в Москве"}</h1>
          <h4>
            <i>Голосовой бот</i> <span>на искуственом интелекте{window.location.hostname == 'msk.baksbo.ru' && " в Москве"} под любые задачи!</span>
            <h3>Разработка под ключ / Обучение по разработке</h3>
          </h4>
          <p>Начните <i>зарабатывать больше с помощью голосового бота,</i> который приведет вам больше клиентов в бизнес, а для фрилансеров возможность разрабатывать бота для бизнеса и зарабатывать на этом{window.location.hostname == 'msk.baksbo.ru' && " в Москве"}!</p>
          <p>Почему нужно выбирать именно нас?</p>
          <ul>
            <li>Опытная команда разработчиков и маркетологов</li>
            <li>Индивидуальное обучение по разработке</li>
            <li>Озвучка профессиональными дикторами</li>
            <li>Посекундная тарификация = 3,8 руб минута звонка</li>
            <li>Собственная телефония без доп. оплат</li>
            <li>Управление через личный кабинет с любого устройства</li>
          </ul>
          <div className='homeHeaderButtonDiv'>
            <a href="#bot_tarifs">
              <button className='getPresent'>Голосовой бот под ключ</button>
            </a>
            <a href="#bot_education">
              <button className='getPresent'>Обучение по разработке бота</button>
            </a>
          </div>
        </div>
        <div className="homeHeaderImage">
          <img src="/static/img/bot/bot_header_image.png" alt="" />
        </div>
      </div>
    </>
  )
}

export default BotHeader