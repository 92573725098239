import ChatFon from '../../elements/chatFon/ChatFon';
import Mailing from '../../elements/mailing/mailing';
import { Helmet } from 'react-helmet';



export default function Home() {
    return (
        <>
            <Helmet>
                <title>
                    {window.location.hostname == 'msk.baksbo.ru' ?
                        'Массовая рассылка сообщений заказать в Москве цена в онлайн-сервисе BAKSBO' :
                        'Массовая рассылка сообщений заказать на сайте цена в онлайн-сервисе BAKSBO'
                    }
                </title>
                <meta
                    name="description"
                    content={window.location.hostname == 'msk.baksbo.ru' ?
                        'Массовая рассылка сообщений на телефон цена в Москве в интернет-сервисе BAKSBO. Заказать рассылку писем клиентам в мессенджерах или соцсетях вы можете на сайте нашей компании онлайн.' :
                        'Массовая рассылка писем на телефон заказать в интернет-сервисе BAKSBO. Купить рассылку сообщений клиентам в мессенджерах или соцсетях недорого вы можете на сайте нашей компании. '
                    }
                />
                <meta
                    name="keywords"
                    content={window.location.hostname == 'msk.baksbo.ru' ?
                        "рассылка сообщений, заказать, москва, цена, онлайн, сервис, интернет, клиент, мессенджер, соцсеть, сайт, компания, стоимость, письмо, отправка сообщений, номер телефона, доставка, привлечение, потерянный, бывший клиент, сеть, сервис массовой рассылки сообщений, сервис рассылки сообщений в соцсетях, сервис рассылки сообщений в мессенджерах, массовая рассылка сообщений в соцсетях, рассылка сообщений по всему миру, baksbo ru." :
                        "массовая рассылка сообщений, заказать, сайт, цена, онлайн, сервис, телефон, интернет, соцсети, мессенджеры, недорого, компания, номер телефона, письмо, привлечение клиентов, бывшие, потерянные, потенциальные, купить"
                    }
                />
                <link rel="canonical" href={window.location.href} />
            </Helmet>


            <ChatFon />
            <div className="eclipse"></div>
            <div className='homeHeader'>
                <div className="homeHeaderText">
                    <h1 className='hiddetH1Seo'>Рассылка сообщений в соцсети и мессенджеры {window.location.hostname == 'msk.baksbo.ru' && " в Москве"}</h1>
                    <h4>Рассылка сообщений {window.location.hostname == 'msk.baksbo.ru' && "в Москве"}
                        <h2>в соцсети и мессенджеры</h2>
                    </h4>
                    <p>Продавайте свой продукт целевой аудитории гораздо быстрее и качественней, <i>каждое сообщение доходит до клиента с уведомлением.</i></p>
                    <div className='homeHeaderButtonDiv'>
                        <a href="#freeConsultation">
                            <button className='getPresent'>Консультация</button>
                        </a>
                        {/* <a href="#selfMailing">
                            <button className='selfMailig'>Самостоятельная рассылка</button>
                        </a> */}
                        <div className="headerSocialLinks">
                            <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/baksbo.ru/">
                                <img src="static/img/icons/insta.png" alt="" />
                            </a>

                            <a target="_blank" rel="noopener noreferrer" href="https://t.me/baksbo_ru">
                                <img src="static/img/icons/tg.png" alt="" />
                            </a>

                            <a target="_blank" rel="noopener noreferrer" href="https://wa.me/+789209660591">
                                <img src="static/img/icons/waapp.png" alt="" />
                            </a>

                            <a target="_blank" rel="noopener noreferrer" href="https://vk.com/baksbo_ru">
                                <img src="static/img/icons/vk.png" alt="" />

                            </a>
                        </div>
                    </div>
                    <div className='rates'>
                        <div className="rate">
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star2.png" alt="" />
                            <span>4.8 Яндекс</span>
                        </div>
                        <div className="rate">
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star2.png" alt="" />
                            <span>4.9 Google</span>
                        </div>
                        <div className="rate">
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <span>5.0 Авито</span>
                        </div>
                        <div className="rate">
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <span>5.0 Wdomain</span>
                        </div>
                    </div>
                </div>
                <div className="homeHeaderImage">
                    <img src="static/img/hand.png" alt="" />
                </div>
            </div>
            <p className='headerSocialText'>Мы тут есть</p>
            <div id='headerSocialLinks' className="headerSocialLinks">
                <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/baksbo.ru/">
                    <img src="static/img/icons/insta.png" alt="" />
                </a>

                <a target="_blank" rel="noopener noreferrer" href="https://t.me/baksbo_ru">
                    <img src="static/img/icons/tg.png" alt="" />
                </a>

                <a target="_blank" rel="noopener noreferrer" href="https://wa.me/+789209660591">
                    <img src="static/img/icons/waapp.png" alt="" />
                </a>

                <a target="_blank" rel="noopener noreferrer" href="https://vk.com/baksbo_ru">
                    <img src="static/img/icons/vk.png" alt="" />

                </a>
            </div>

            <Mailing />
        </>
    )
}